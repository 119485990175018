.b-search_noresults {
	display: grid;
	grid-template: 'tips separator services' / 1fr 1fr 1fr;

	@include media(md) {
		grid-template: 'tips separator services' / 3fr 1fr 3fr;
	}

	@include media(sm) {
		grid-template:
			'tips'
			'separator'
			'services';
		row-gap: rh(9);
	}

	&::before {
		background: $color-search-separator;
		content: '';
		grid-area: separator;
		height: 100%;
		justify-self: center;
		width: 1px;

		@include media(sm) {
			height: 1px;
			width: 100%;
		}
	}

	&-tips {
		grid-area: tips;
	}

	&-services {
		grid-area: services;
	}

	&-copy {
		font-size: 16px;
		letter-spacing: 2.4px;
		line-height: 24px;
		margin-bottom: rh(8);
		text-transform: uppercase;
	}

	&-keywords_wrap {
		font-weight: bold;
	}
}
