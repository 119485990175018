.b-product_grid {
	margin-bottom: rh(20);

	@include media(sm) {
		margin-bottom: rh(12);
	}

	&.m-grid {
		display: grid;
		gap: rh(20) var(--grid-gutter);
		grid-template-columns: repeat(3, 1fr);

		@include media(sm) {
			row-gap: rh(12);

			&.single-product {
				grid-template-columns: repeat(1, 1fr);
			}

			&.double-product {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	&.no-filter-grid {
		grid-template-columns: repeat($configuration-no-filter-grid-template-columns, 1fr);

		@include media(md) {
			grid-template-columns: repeat($configuration-noof-grid-template-columns, 1fr);
		}
	}

	&-load_more_container {
		display: grid;
		gap: 0 var(--grid-gutter);
		grid-template:
			'. indicator .'
			'. button_more .'
			'. button_less .'
			/ 1fr 1fr 1fr;
		text-align: center;

		@include media(sm) {
			grid-template:
				'indicator'
				'button_more'
				'button_less';
		}
	}

	&-product_indicator {
		grid-area: indicator;
		margin-bottom: rh(5);
	}

	&-show_more {
		grid-area: button_more;
	}

	&-show_less {
		grid-area: button_less;
		margin-bottom: rh(5);
	}
}
