.b-wines_nav{
    @include media(md-down){
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: 50%;
        overflow-x: hidden;
    }

    ul{
        display: flex;
        justify-content: center;
        gap: 50px;
        // max-width: 834px;
        margin:auto;
        padding: 0 0 60px;
        overflow-x:scroll;
        width: 100%;
        @include media(md-down){
            width: 100vw;
            justify-content: flex-start;
            padding: 0 24px 60px;
        }
        @include media(xs){
            gap: 22px;
        }
        li{
            max-width: 170px;
            text-align: center;
            flex-shrink: 0;
            @include media(sm){
                width: 117px;
            }
            a{
                text-decoration: none;
            }
            span{
                font-size: 18px;
                font-weight: bold;
                font-family: $font-alt;
                &:after{
                    content: '';
                    display: none;
                }
                &.show-product-count{
                    &:after{
                        content: attr(data-count);
                        display: block;
                        font-size: 14px;
                        font-family: $font-main;
                        font-weight: normal;
                    }
                }
            }
            img{
                border-radius: 50%;
                border: 4px solid transparent;
                transition: $motion-ease;
            }
            &:hover{
                img{
                    border: 4px solid $color-accent;
                }
            }
        }
    }
}