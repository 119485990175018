.b-refinements_slide_panel {
	@include g-dialog_backdrop;

	--dialog-z: #{z(modal)};

	&::after {
		display: none;
	}

	&.m-active {
		@include g-dialog_backdrop(active);
	}

	&-overlay {
		transition: visibility $motion-ease-panels;
		visibility: hidden;

		&::after {
			background-color: transparent;
			bottom: -200px;
			content: '';
			left: 0;
			opacity: 0;
			position: fixed;
			right: 0;
			top: -200px;
			transition: $motion-ease-panels;
			transition-property: opacity, background-color;
			z-index: 1;
		}
	}

	&.m-active &-overlay {
		overflow-y: scroll;
		visibility: visible;

		&::after {
			background-color: rgba($color-overlay-background, 0.5);
			opacity: 1;
		}
	}

	&-inner {
		background-color: $color-refinements_slide_panel-background;
		bottom: 0;
		height: 100%;
		left: 0;
		overflow: hidden;
		position: fixed;
		top: 0;
		transform: translateX(-100%);
		transition: $motion-ease-panels;
		transition-property: visibility, transform;
		visibility: hidden;
		width: 50%;
		z-index: z(modal);

		@include media(md-down) {
			&.m-no_transition {
				transition: none !important; // stylelint-disable-line
			}
		}

		@include media(sm) {
			width: 100%;
		}

		&.m-opened {
			box-shadow: $depth-3;
			transform: translateX(0);
			visibility: visible;
		}

		&.m-opened .b-refinements {
			display: block;
		}
	}
}
