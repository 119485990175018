.b-refinement_navigation {
	&-sublist {
		margin: rh(3 0 0 3);
	}

	&-item {
		@include t-paragraph_2;

		margin-bottom: rh(3);
	}

	&-link {
		border-bottom: 1px solid transparent;

		&:hover {
			text-decoration: none;

			@include media(lg-up) {
				border-color: $color-refinements-text;
			}
		}

		&.m-selected {
			border-color: $color-refinements-text;
			display: inline-block;
		}
	}

	&.m-collapsed &-item:nth-child(n + 6) {
		display: none;
	}
}
