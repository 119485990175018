.b-plp_sorting {
	position: relative;

	&-label {
		color: $color-sorting-label;
		font-size: 16px;
		line-height: 28px;
		margin-right: rh(2);

		@include media(md-up) {
			&::after {
				content: ':';
			}
		}

		@include media(md-down) {
			align-items: center;
			background: $color-form-input-background;
			border: 2px solid $color-form-input;
			color: $color-form-input;
			display: flex;
			font-size: 14px;
			font-weight: bold;
			height: 100%;
			justify-content: center;
			left: 0;
			letter-spacing: 3px;
			line-height: 24px;
			margin: 0;
			position: absolute;
			right: 0;
			text-align: center;
			text-transform: uppercase;

			@include icon(arrow-down, $position: after, $icon-width: 18px, $icon-height: 12px) {
				margin-left: rh(2);
			}
		}
	}

	&-container {
		@include f-select($_mod: alt);

		height: 60px;
		margin: 0;

		@include media(md-up) {
			align-items: center;
			display: flex;
			justify-content: flex-end;
		}
	}

	&-field {
		@include media(md-down) {
			opacity: 0;
			width: 100%;
		}
	}

	&-select {
		cursor: pointer;

		&.f-input_select-field { //Fix for mobile zooming issue
			@include media(sm) {
				font-size: 16px;
			}
		}
	}

	&.horizontal {
		&-container {
			align-items: center;
			max-width: 1560px;
			display: flex;
			justify-content: space-between;
			flex-basis: 100%;
			flex-direction: row;
			width: 88vw;

			@include media(md-down) {
				display: none;
			}

			.b-filter_right {
				display: flex;
			}
		}

		&-sort {
			margin-right: 8px;
		}

		&-title {
			border-bottom: 0;
			padding: 8px;
		}

		&-heading {
			border-bottom: 0;
			margin-left: 8px;
			padding-bottom: rh(2);
		}

		&-clear_filter {
			display: flex;
			justify-content: center;
			margin: 0 0 12px 6px;

			&__button {
				background-position: 0 70%;
				margin: 0;
			}

			&__no_sort_button {
				margin: 0;
			}
		}

		&-clear_filter_sort {
			margin: 0 8px 0 66px;
			padding-bottom: 10px;
		}

		&-heading_clear_filter {
			border-bottom: 0;
			margin-left: 8px;
			padding-bottom: rh(2);
		}

		&-no_sort_clear_filter {
			margin: 0 6px;
		}
	}
}
