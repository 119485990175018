.b-search_content {
	display: grid;
	gap: rh(16) var(--grid-gutter);
	grid-template-columns: repeat(3, 1fr);
	margin-bottom: rh(20);

	@include media(md) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include media(sm) {
		grid-template-columns: 1fr;
		margin-bottom: rh(12);
		row-gap: rh(12);
	}

	&-date {
		@include t-paragraph_3;

		margin-bottom: rh(4);
		text-transform: uppercase;
	}

	&-image {
		width: 100%;
	}

	&-image_wrapper {
		display: block;
		margin-bottom: rh(6);
	}

	&-title {
		@include t-heading_2;
		@include lines-clamp(2);

		margin-bottom: 20px;
	}

	&-description {
		@include t-paragraph_2;
		@include lines-clamp($lines: 2);

		margin-bottom: rh(6);
		overflow: hidden;
	}

	&-link {
		@include t-link_3;
	}
}
