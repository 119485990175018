.b-slide_panel {
	height: 100%;

	&-container {
		height: 100%;

		@include media(md-down) {
			display: grid;
			grid-template-rows: auto auto 1fr;
		}
	}

	&-content {
		overflow-y: auto;
		padding: rh(0 5);
	}

	&-title {
		border-bottom: 1px solid $color-refinements-border_color;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 2.1px;
		line-height: 15px;
		padding: rh(6 5);
		text-transform: uppercase;
	}

	&-close {
		@include icon(close, $icon-width: 17px, $icon-height: 17px);

		cursor: pointer;
		padding: 20px;
		position: absolute;
		right: 10px;
		top: 10px;
	}

	&-actions {
		background: $color-refinements-background;
		border-top: 1px solid $color-refinements-border_color;
		display: grid;
		gap: rh(0 3);
		grid-template-columns: 1fr 1fr;
		padding: rh(2 3);
		width: 100%;

		@include media(md-down) {
			align-self: flex-end;
		}
	}

	&-apply {
		@include g-button(main);
	}

	&-clear {
		@include g-button(alt);
	}
}
