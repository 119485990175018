.b-no_refinements {
	text-align: center;

	&-label {
		display: flex;
		font-size: 14px;
		font-weight: bold;
		justify-content: center;
		letter-spacing: 2.1px;
		line-height: 24px;
		margin-bottom: rh(4);
		text-align: center;
		text-transform: uppercase;

		@include media(sm) {
			margin-bottom: rh(9);
		}
	}

	&-count {
		margin-right: rh(1);
	}

	&-description {
		@include t-paragraph_1;

		margin-bottom: rh(14);

		@include media(sm) {
			margin-bottom: rh(10);
		}
	}

	&-button {
		@include g-button(main);

		margin: 0 auto;
		min-width: 295px;
	}

	.b-filter_selected {
		justify-content: center;
		margin-bottom: rh(14);

		@include media(sm) {
			margin-bottom: rh(10);
		}
	}
}
