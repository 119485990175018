.b-refinements-inner {
	position: relative;
}

.b-refinements_accordion {
	@include g-accordion;

	&-description {
		@include t-paragraph_2;
		@include lines-clamp($lines: 3, $line-height: 24px);

		margin-bottom: rh(5);
	}

	&-item {
		@include g-accordion(item);
	}

	&-button {
		@include g-accordion(control);

		color: $color-refinements-text;
		font-weight: normal;

		&.m-opened {
			@include g-accordion(control, opened);
		}
	}

	&-horizontal {
		display: flex;
		max-width: 1560px;
		position: absolute;
		top: 76px;
		width: 88vw;
		height: 64px;
		z-index: 1;

		@include media(md-down) {
			display: none;
		}

		&_title_container {
			display: flex;
			flex-wrap: wrap;
			max-width: 80%;
			padding: 10px 5px;
			text-align: left;

			&__title {
				font-size: $configuration-horizontal-filter-title-font-size;
				font-weight: $configuration-horizontal-filter-font-weight;
				width: 100%;
				letter-spacing: 0.12px;
				line-height: 16px;
			}

			&__selected_item {
				font-size: $configuration-horizontal-filter-selected-item-font-size;
				grid-row: auto;
				letter-spacing: 0.15px;
				line-height: 24px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&_button {
			@include g-accordion(horizontalfilter);

			border: 1px solid $color-horizontal-filter-border;
			color: $color-refinements-text;
			font-weight: normal;
			height: rh(12);

			&.m-opened {
				@include g-accordion(horizontalfilter, opened);
			}
		}

		&_item {
			@include g-accordion(horizontalitem);

			flex-grow: 1;
			margin: 0 8px;
			width: 0;
		}

		&_content {
			@include g-accordion(horizontalcontent);

			&.m-opened {
				@include g-accordion(horizontalcontent, expanded);
			}

			&__inner_content {
				&.m-opened {
					overflow: unset;
					padding-bottom: 0;
				}
			}

			&_content_align {
				margin: 20px 12px;
			}
		}

		&_m_hide {
			display: none;
		}
	}

	&-content {
		@include g-accordion(content);

		position: static;

		&.m-opened {
			@include g-accordion(content, expanded);
		}
	}

	&-content.m-opened &-description {
		@include force_repaint; // GAL-1760: iOS -trigger repaint for line-clamp. (Repaint issue)
	}

	&-content_inner {
		@include g-accordion(content_inner);
	}
}
