.b-refinement_list {
	&-item {
		margin: rh(0 0 4);

		@include media(md-down) {
			margin: rh(0 0 3);
		}
	}

	&-more {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		margin-top: rh(5);

		@include icon(plus, $position: after, $icon-width: 10px, $icon-height: 10px) {
			margin-left: rh(1);
		}
	}

	&.m-collapsed &-item.m-last_visible ~ &-item {
		display: none;
	}

	.f-input_radio {
		margin: rh(0 0 5);

		@include media(sm) {
			margin: rh(0 0 4);
		}
	}
}
