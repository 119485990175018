.b-search_tips {
	&-title {
		@include t-heading_6;

		margin-bottom: rh(3);
	}

	&-list {
		@include t-list;
	}
}
