.l-search {
	@include g-section_holder;

	&-header {
		margin: rh(12 0 9);
		text-align: center;

		@include media(sm) {
			margin: rh(6 0 8);
		}
	}

	&-header_title {
		@include t-heading_4;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&-header_keywords {
		@include t-link_2;
		// as far as our url link and our keywords link are the same, link become gold, on design should be black
		&:visited {
			color: $color-filter-text;
		}
	}

	&-header_keywords_wrap {
		display: flex;
		justify-content: center;
		margin-left: rh(2);
		word-break: break-word;
	}

	&-content {
		margin-bottom: rh(20);

		@include media(sm) {
			margin-bottom: rh(16);
		}
	}

	&-content .l-products_results-load_more_container {
		margin-top: rh(20);

		@include media(sm) {
			margin-top: rh(14);
		}
	}

	&-no_result {
		margin-bottom: rh(30);

		@include media(sm) {
			margin-bottom: rh(6);
		}
	}

	.b-carousel {
		margin-bottom: rh(18);

		@include media(sm) {
			margin-bottom: rh(10);
		}
	}

	.b-customer_service_info {
		&-title,
		&-msg {
			margin-bottom: rh(8);
		}

		&-item {
			margin-bottom: rh(6);
		}
	}

	.b-tab_list {
		margin: rh(0 0 8);

		@include media(sm) {
			margin: rh(0 0 7);
		}
	}
}
