@charset "UTF-8";

@import 'main';
@import '04-layouts/l-search';
@import '04-layouts/l-plp';
@import '05-blocks/plp/all';

// Show page content after all
@import '05-blocks/page-show';

.b-product_badge {
	&-image {
		background: none !important;
	}

	&-critics {
		border: 2px dashed $color-primary;
	}

	&-criticslabel {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		text-align: center;
		width: 100%;

		@include media(md-down) {
			line-height: 0;
			padding-top: 4px;
		}

		span {
			font-size: 16px;
			letter-spacing: 0.15px;
			line-height: 24px;

			@include media(md-down) {
				font-size: 12px;
				letter-spacing: 0.12px;
				line-height: 14px;
			}

			&.criticpoints {
				font-size: 32px;
				letter-spacing: 0.31px;

				@include media(md-down) {
					font-size: 20px;
					letter-spacing: 0.19px;
				}
			}

			&.abbreviation1 {
				@include media(md-down) {
					display: none;
				}
			}
		}
	}
}
