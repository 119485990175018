.b-filter_selected {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	text-align: left;

	&-list {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;

		@include media(sm) {
			align-items: stretch;
			display: grid;
			gap: var(--grid-gutter);
			grid-template-columns: repeat(2, 1fr);
			margin: rh(0 0 4);
			width: 100%;
		}
	}

	&-title {
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 2.1px;
		line-height: 24px;
		margin-bottom: rh(3);
		text-transform: uppercase;

		@include media(sm) {
			width: 100%;
		}
	}

	&-item {
		border: 1px solid $color-filters-border;
		border-radius: $configuration-filter_selected-border_radius;
		display: grid;
		gap: rh(0 2);
		grid-template:
			'label button'
			'value button'/ 1fr auto;
		margin: rh(0 3 3 0);
		min-width: 160px;
		padding: rh(1 2);

		@include media(sm) {
			margin: 0;
			min-width: 0;
		}
	}

	&-label {
		align-self: end;
		color: $color-filters-label;
		font-size: 10px;
		font-weight: 600;
		grid-area: label;
		letter-spacing: 1.5px;
		line-height: 14px;
		text-transform: uppercase;
		width: 100%;
	}

	&-value {
		align-self: start;
		font-size: 12px;
		font-weight: bold;
		grid-area: value;
		letter-spacing: 2.1px;
		line-height: 16px;
		text-transform: uppercase;
	}

	&-remove {
		@include icon(close, $icon-width: 10px, $icon-height: 11px);

		cursor: pointer;
		grid-area: button;

		@include media(sm) {
			align-items: center;
			display: flex;
			justify-content: flex-end;
		}
	}

	&-button {
		@include t-link_2;

		cursor: pointer;
		margin: rh(2 4);

		@include media(sm) {
			@include g-button(alt);

			grid-column: span 2;
			margin: 0;
		}
	}
}
