/*
# Product grid template

## Regular Desktop Grid

```
|-------------title----------------|
|sidebar|---product_number--sorting|
|sidebar|---products---------------|
|-------|--------load-more---------|
```

## Selected Filters

```
|------ ------title----------------|
|sidebar|---product_number--sorting|
|sidebar|---selected_filters-------|
|sidebar|---products---------------|
|-------|--------load-more---------|
```

## Regular Tablet/Mobile Grid

```
|------title-----|
|sidebar--sorting|
|selected_filters|
|-product_number-|
|----products----|
|----load-more---|
```

*/

.l-products_results {
	display: grid;
	gap: 0 var(--grid-gutter);
	grid-template:
		[start sidebar-s products_number-s sorting-s no_refinements-s]
		auto //1
		[products_number-e sorting-e no_refinements-e selected_filters-s]
		auto //2
		[selected_filters-e products-s]
		auto //3
		[products-e]
		1fr //4
		[end sidebar-e]
		/
		[start sidebar-s no_refinements-s]
		1fr //1
		[]
		1fr //2
		[]
		1fr //3
		[sidebar-e products_number-s products-s selected_filters-s]
		1fr //4
		[]
		1fr //5
		[]
		1fr //6
		[]
		1fr //7
		[]
		1fr //8
		[products_number-e sorting-s]
		1fr //9
		[]
		1fr //10
		[]
		1fr //11
		[]
		1fr //12
		[end sorting-e products-e selected_filters-e no_refinements-e];

	@include media(md-down) {
		grid-template:
			[start title-s]
			auto //1
			[title-e sorting-s]
			auto //2
			[sorting-e selected_filters-s]
			auto //3
			[selected_filters-e products_number-s]
			auto //4
			[products_number-e products-s no_refinements-s]
			1fr //5
			[products-e no_refinements]
			auto //6
			[end]
			/
			[start title-s sidebar-s products_number-s sorting-s selected_filters-s products-s  no_refinements-s]
			1fr //1
			[]
			1fr //2
			[]
			1fr //3
			[]
			1fr //4
			[end  title-e sidebar-e products_number-e sorting-e selected_filters-e products-e no_refinements-e];
	}

	&-sidebar {
		grid-area: sidebar-s / sidebar-s / sidebar-e / sidebar-e;
		padding-right: rh(13);

		.b-refinements {
			@include media(md-down) {
				display: none;
			}
		}
	}

	&-products_number {
		font-size: 14px;
		font-weight: bold;
		grid-area: products_number-s / products_number-s / products_number-e / products_number-e;
		letter-spacing: 2.1px;
		line-height: 60px;
		margin-bottom: rh(5);
		text-transform: uppercase;

		@include media(md-down) {
			line-height: 20px;
			margin-bottom: rh(7);
		}

		&.align-text {
			margin-top: 95px;
			text-align: center;

			@include media(md-down) {
				margin-top: 0;
			}
		}

		&__no_grid {
			flex-basis: 50%;
			grid-area: none;
			margin: 0;

			@include media(sm) {
				text-align: left;
			}
		}

		&__horizontal_filter {
			display: flex;
			justify-content: center;
			margin: 0;
		}

		&__text_alignment {
			@include media(sm) {
				text-align: left;
			}
		}

	}

	&-sorting {
		grid-area: sorting-s / sorting-s / sorting-e / sorting-e;

		@include media(md-down) {
			align-items: center;
			display: grid;
			gap: rh(0 3);
			margin-bottom: rh(6);
		}

		@include media(sm) {
			margin-bottom: rh(7);
		}

		&__hide_sort {
			display: none;
		}

		&__grid {
			@include media(sm) {
				grid-template-columns: 1fr 1fr;
			}

			@include media(md) {
				grid-template-columns: none;
			}
		}

		&__no_grid {
			flex-basis: 50%;
			grid-area: none;

		}

		&__no_sort_filter {
			@include media(md-down) {
				grid-template-columns: none;
			}
		}

		&__no_filter_sort {
			@include media(md-down) {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	&-products_filters_selected {
		grid-area: selected_filters-s / selected_filters-s / selected_filters-e / selected_filters-e;
		margin-bottom: rh(8);

		@include media(md-down) {
			margin-bottom: rh(7);
		}
	}

	&-grid {
		grid-area: products-s / products-s / products-e / products-e;

		&.m-busy {
			cursor: wait;
			opacity: 0.6;
		}
	}

	&-no_refinements {
		grid-area: no_refinements-s / no_refinements-s / no_refinements-e / no_refinements-e;
	}

	&-refinement_button {
		@include g-button(main);
	}

	&-search_results {
		display: flex;

		@include media(md) {
			align-items: unset;
			flex-direction: column-reverse;
		}

		@include media(sm) {
			flex-direction: column-reverse;
		}
	}
}
