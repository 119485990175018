.l-plp {
	&-wrapper {
		@include g-section_holder;

		padding-top: rh(7);
	}

	&-content {
		margin-bottom: rh(24);

		@include media(sm) {
			margin-bottom: rh(16);
		}
	}

	&-title {
		@include t-heading_1;

		margin: rh(5 0 12);
		text-align: center;

		@include media(md) {
			margin-bottom: rh(5 0 7);
		}

		@include media(sm) {
			margin-bottom: rh(5 0 7);
		}
	}

	&-recently_viewed {
		margin-left: var(--grid-margin, 80px);
	}

	.b-carousel {
		margin-bottom: rh(20);
	}
}
